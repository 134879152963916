button,
input,
optgroup,
select,
textarea
  vertical-align middle
  color inherit
  font inherit
  border 0
  background transparent
  padding 0
  margin 0
  outline 0

select
  -webkit-appearance none
  appearance none
  border-radius 0

button,
input
  overflow visible

button,
select
  text-transform none

select::-ms-expand
  display none

button,
[type='button'],
[type='reset'],
[type='submit']
  cursor pointer
  -webkit-appearance none
  appearance none


button[disabled],
[type='button'][disabled],
[type='reset'][disabled],
[type='submit'][disabled]
  cursor default

button-moz-focus-inner,
[type='button']-moz-focus-inner,
[type='reset']-moz-focus-inner,
[type='submit']-moz-focus-inner
  border-style none
  padding 0

button-moz-focusring,
[type='button']-moz-focusring,
[type='reset']-moz-focusring,
[type='submit']-moz-focusring
  outline 1px dotted ButtonText

fieldset
  margin 0
  padding 0
  border 0
  min-width 0

legend
  color inherit
  display table
  max-width 100%
  padding 0 /* 3 */
  white-space normal

progress
  vertical-align baseline

textarea
  overflow auto

[type='checkbox'],
[type='radio']
  box-sizing border-box
  padding 0

[type='number']-webkit-inner-spin-button,
[type='number']-webkit-outer-spin-button
  height auto

[type='search']
  -webkit-appearance textfield
  outline-offset -2px

[type='search']-webkit-search-decoration
  -webkit-appearance none

input:-webkit-autofill
  -webkit-box-shadow 0 0 0px 1000px #fff inset;

-webkit-file-upload-button
  -webkit-appearance button
  font inherit

-webkit-input-placeholder
  font inherit

-ms-input-placeholder
  font inherit

-ms-input-placeholder
  font inherit

placeholder
  font inherit

label
  box-sizing border-box

label[for]
  cursor pointer