.dev-space
  height 100px

.top-banner
  position relative
  margin 0 auto
  // padding 52px 10px 28px
  padding 52px 10px 38px
  width 960px
  min-height 100px
  background-image url("assets/images/top-banner_bg.svg")
  border-radius 10px

  +media_pb()
    width calc(100vw - 20px)

  +media_sp()
    padding 30px 10px 16px
    width calc(100vw - 40px)

.top-banner-headding
  position absolute
  padding 5px 12px 5px 0
  min-width 110px
  text-align right
  left 0
  top 15px
  color #fff
  font-size 17px
  background-color rgba(#e9546b, .9)

.top-banner-inner
  display flex
  justify-content space-around
  margin 0 auto
  width 940px

  +media_pb()
    width 100%

  +media_sp()
    display block
    width 100%

.top-banner-inner2
  display flex
  justify-content space-around
  margin 32px auto 0
  width 940px

  +media_pb()
    width 100%

  +media_sp()
    display block
    width 100%

.top-banner-left,
.top-banner-center,
.top-banner-right
  width 280px
  text-align center

  +media_pb()
    width 31%

  +media_sp()
    margin-top 30px
    width 100%

.top-banner-image
  width 100%

.top-banner-title
  font-size 16px
  font-weight bold

  +media_pb()
    font-size 13px

.top-banner-photo
  display block
  position relative
  margin-top 12px
  overflow hidden
  border-radius 10px
  box-shadow 3px 3px rgba(#000, .25)
  transition .3s ease

.top-banner-photo:hover
  opacity .8

  +media_sp()
    opacity 1

.top-banner-tag1,
.top-banner-tag2,
.top-banner-tag3,
.top-banner-tag4,
.top-banner-tag5
  position absolute
  padding 7px 16px
  min-width 110px
  right 0
  bottom 9px
  color #fff
  font-size 15px
  border-radius 15px 0 0 15px

.top-banner-tag1
  background-color rgba(#fbb03b, .85)

.top-banner-tag2
  background-color rgba(#e9546b, .85)

.top-banner-tag3
  background-color rgba(#8cc63f, .85)

.top-banner-tag4
  background-color rgba(#d80c18, .85)

.top-banner-tag5
  background-color rgba(#fbb03b, .85)
