*
  box-sizing border-box

before,
after
  box-sizing inherit

html
  -webkit-tap-highlight-color transparent
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

body
  line-height 1
  font-size 62.5%
  font-feature-settings 'palt' 1
  text-rendering optimizeLegibility
  text-size-adjust 100%
  backface-visibility hidden
  -webkit-overflow-scrolling touch
  -ms-overflow-style scrollbar
  //font_default()
  // font_set('Lato', 'Noto Sans JP', 'Hiragino Sans', 'ヒラギノ角ゴシック', 'メイリオ', Meiryo, sans-serif)
  // font-family 'Lato', 'Noto Sans JP', '游ゴシック Medium', '游ゴシック体', 'Yu Gothic Medium', YuGothic, 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif

body,
p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl
  margin 0

main,
section,
details
  display block

h1,
h2,
h3,
h4,
h5,
h6
  font-size inherit
  line-height inherit
  font-weight inherit
  margin 0

ul,
ol
  margin 0
  padding 0
  list-style none

dt
  font-weight bold

dt
  font-weight bold

dd
  margin-left 0

hr
  box-sizing content-box
  height 0
  overflow visible
  border 0
  border-top 1px solid
  margin 0
  clear both

pre
  font-family monospace, monospace
  font-size inherit
  white-space pre-wrap

address
  font-style inherit

a
  background-color transparent
  text-decoration none
  outline none
  color inherit
  -webkit-text-decoration-skip objects

abbr[title]
  border-bottom none
  text-decoration underline
  text-decoration underline dotted

rt
  font-size 50%
  text-align start
  line-height normal
  -webkit-text-emphasis none

b,
strong
  font-weight bolder

sub,
sup
  font-size 75%
  line-height 0
  position relative
  vertical-align baseline

sub
  bottom -0.25em

sup
  top -0.5em

img
  border-style none
  vertical-align bottom

embed,
object,
iframe
  border 0
  vertical-align bottom

summary
  display list-item

table
  border-collapse collapse
  border-spacing 0

caption
  text-align left

td,
th
  vertical-align top

th
  text-align left
  font-weight bold

template
  display none

[hidden]
  display none