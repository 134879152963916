@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600;700&display=swap')

@font-face
  font-family "MyYuGothicM"
  font-weight normal
  src local("YuGothic-Medium"),
       local("Yu Gothic Medium"),
       local("YuGothic-Regular")

@font-face
  font-family "MyYuGothicM"
  font-weight bold
  src local("YuGothic-Bold"),
       local("Yu Gothic")

font_default()
  font-family 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', MyYuGothicM, 'メイリオ', Meiryo, sans-serif

font_mincho()
  font-family "游明朝", "YuMincho", "Hiragino Mincho ProN", "Hiragino Mincho Pro", 'Noto Serif JP', "ＭＳ 明朝", serif

font_english()
  font-family 'Helvetica Neue', 'Segoe UI', Verdana, 'Open Sans', sans-serif

font_code()
  font-family 'Source Han Code JP', 'Source Code Pro', Menlo, Consolas, 'Bitstream Vera Sans Mono', monospace

font_catch()
  font-family proxima-nova, 'Helvetica Neue', 'Segoe UI', Verdana, sans-serif

font_set(fonts)
  font-family fonts