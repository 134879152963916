.contact
  margin-top 100px !important

  +media_sp()
    margin-top 80px !important

.no-touch .contact
  margin-top 68px !important

.charm
  +media_sp()
    width 100%
    padding 0 20px

.charm-title
  padding 60px 0 0
  >img
    margin 0 auto

  +media_sp()
    margin 0 -10px
    padding 40px 0 20px
    >img
      width 100%

.charm-arrow
  margin-top 40px
  >img
    margin 0 auto
    display block

  +media_sp()
    margin-top 20px

.wpcf7
  margin 40px auto 0
  width 820px

  +media_sp()
    margin 20px auto 0
    width 100%

.charm-item
  display table
  margin-bottom 30px
  table-layout fixed
  width 100%
  font-size 22px
  >span
    display table-cell
  +br
    display none

  +media_sp()
    display block
    >span
      display block

.charm-item2
  display block
  margin 20px 0 60px
  width 100%
  font-size 22px
  >span
    display block
    width 100%
  +br
    display none

  +media_sp()
    margin 20px 0 80px

.charm-headding
  width 270px

  +media_sp()
    padding 0 0 12px

.charm-headding2
  position relative
  padding 12px 0

  +media_sp()
    padding 0 0 12px

.charm-note
  display block
  position absolute
  top 0
  right 0
  font-size 12px

  +media_sp()
    top inherit
    bottom -270px
    font-size 16px

.charm-required
  color #f06836

.wpcf7-text
  padding 12px 10px
  width 100%
  border solid 1px #ccc

.wpcf7-select
  padding 12px 10px
  width 100%
  border solid 1px #ccc
  background url(assets/images/provide-charm/charm-down.png) right 30px center no-repeat

.wpcf7-textarea
  padding 12px 10px
  width 100%
  height 340px
  line-height 1.5
  font-size 18px
  border solid 1px #ccc

  +media_sp()
    height 240px

.charm-button
  margin 0 auto
  width 820px

  +media_sp()
    width 100%

.wpcf7-confirm,
.wpcf7-submit
  display block
  width 820px
  height 100px
  text-indent -9999px
  background #f0df26 url(assets/images/provide-charm/confirm-button.png) center no-repeat
  border-radius 18px
  box-shadow 3px 3px rgba(#ccc, .5)
  transition .4s ease
  cursor pointer

  +media_sp()
    width 100%
    height 70px
    background #f0df26 url(assets/images/provide-charm/confirm-button-sp.png) center no-repeat

.wpcf7-back
  display inline-block
  width 300px
  height 80px
  text-indent -9999px
  background #9f9f9f url(assets/images/provide-charm/back-button.png) center no-repeat
  border-radius 18px
  box-shadow 3px 3px rgba(#ccc, .5)
  transition .4s ease
  cursor pointer

  +media_sp()
    display block
    margin-bottom 20px
    width 100%
    height 70px
    background #9f9f9f url(assets/images/provide-charm/back-button-sp.png) center no-repeat

// .wpcf7-submit
//   display inline-block
//   margin-left 40px
//   width 480px
//   height 80px
//   text-indent -9999px
//   background #f0df26 url(assets/images/provide-charm/submit-button.png) center no-repeat
//   border-radius 18px
//   box-shadow 3px 3px rgba(#ccc, .5)
//   transition .4s ease
//   cursor pointer

//   +media_sp()
//     display block
//     margin-left 0
//     width 100%
//     height 70px
//     background #f0df26 url(assets/images/provide-charm/submit-button-sp.png) center no-repeat

.wpcf7-confirm:hover,
.wpcf7-back:hover,
.wpcf7-submit:hover
  opacity .8

.charm-aside
  margin 80px auto 0
  width 780px
  line-height 1.8
  font-size 14px

  +media_sp()
    margin 40px auto 0
    width 100%
    font-size 18px

.charm-subheadding
  margin 40px auto 0
  width 780px
  font-size 18px
  font-weight bold

  +media_sp()
    width 100%
    font-size 20px

.charm-privacypolicy
  margin 16px auto 60px
  width 780px
  line-height 1.8
  font-size 14px
  >a
    color #00a4cc
    font-weight bold
  >a:hover,
  >span
    text-decoration underline

  +media_sp()
    width 100%
    font-size 18px
    >a:hover,
    >span
      text-decoration none

.charm-provided
  margin 100px auto 0
  width 960px
  text-align center
  font-size 40px
  font-weight bold

  +media_sp()
    margin 50px auto 0
    width 100%
    line-height 1.3
    font-size 30px

.charm-thanks
  margin 30px auto 0
  padding-bottom 80px
  width 960px
  text-align center
  font-size 30px
  font-weight bold
  border-bottom dotted 1px #00a0d2

  +media_sp()
    margin 24px auto 0
    width 100%
    font-size 19px

.wpcf7-not-valid-tip
  margin-top 10px
  font-size 18px
  direction ltr !important

.wpcf7c-conf
  background-color none !important

.wpcf7-response-output
  margin 80px 0 0
  padding 40px !important
  font-size 18px !important
  background-color rgba(#c1effa, .36)

  +media_sp()
    margin 50px 0 0 !important
    padding 20px !important
    line-height 1.3
    font-size 20px !important

.wpcf7 form .wpcf7-response-output
	margin 80px 0 0
	padding 30px
  font-size 16px !important
	border solid 1px #00a0d2

// .wpcf7 form.init .wpcf7-response-output
//   display block !important

.wpcf7 form.sent .wpcf7-response-output
	border solid 1px #00a0d2

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output
	border solid 1px #00a0d2


.wpcf7 form.spam .wpcf7-response-output
	border solid 1px #00a0d2

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output
	border solid 1px #00a0d2