.pages
  margin-top 100px !important

  +media_sp()
    margin-top 80px !important

.no-touch .pages
  margin-top 68px !important

.about-title
  padding 60px 0 24px
  background url(assets/images/about/about-back.png)
  >img
    margin 0 auto

  +media_sp()
    padding 40px 20px 24px
    >img
      width 100%

.about-inner
  margin 0 auto
  width 980px
  background url(assets/images/about/about-inner.png) center top no-repeat

  +media_sp()
    width 100%
    padding 0 20px
    background url(assets/images/about/about-inner-sp.png) left 20px no-repeat
    background-size 100% auto

.about-subtitle
  padding 90px 0 80px
  text-align center
  >img
    margin 0 auto
  +media_sp()
    padding 40px 0 50px
    >img
      width 100%

.about-explanation
  padding 28px 0
  width 100%
  background-color rgba(#c1effa, .36)
  >p
    display table
    margin 0 auto
    line-height 1.9
    font-size 22px

  +media_sp()
    padding 30px 20px
    >p
      display block
      margin 0

.about-link
  padding 90px 0 120px

  +media_sp()
    padding 42px 0

.about-button
  display block
  margin 0 auto
  padding 22px 0
  width 820px
  text-align center
  background-color #f0df26
  border-radius 18px
  box-shadow 3px 3px rgba(#ccc, .5)
  transition .4s ease
  >img
    margin 0 auto

  +media_sp()
    padding 20px 10px
    width 100%
    >img
      width 100%

.about-button:hover
  opacity .8

  +media_sp()
    opacity 1