@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600;700&display=swap");
@font-face {
  font-family: "MyYuGothicM";
  font-weight: normal;
  src: local("YuGothic-Medium"), local("Yu Gothic Medium"), local("YuGothic-Regular");
}
@font-face {
  font-family: "MyYuGothicM";
  font-weight: bold;
  src: local("YuGothic-Bold"), local("Yu Gothic");
}
* {
  box-sizing: border-box;
}
before,
after {
  box-sizing: inherit;
}
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  line-height: 1;
  font-size: 62.5%;
  font-feature-settings: 'palt' 1;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  backface-visibility: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: scrollbar;
}
body,
p,
table,
blockquote,
address,
pre,
iframe,
form,
figure,
dl {
  margin: 0;
}
main,
section,
details {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
dt {
  font-weight: bold;
}
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid;
  margin: 0;
  clear: both;
}
pre {
  font-family: monospace, monospace;
  font-size: inherit;
  white-space: pre-wrap;
}
address {
  font-style: inherit;
}
a {
  background-color: transparent;
  text-decoration: none;
  outline: none;
  color: inherit;
  -webkit-text-decoration-skip: objects;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
rt {
  font-size: 50%;
  text-align: start;
  line-height: normal;
  -webkit-text-emphasis: none;
}
b,
strong {
  font-weight: bolder;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
  vertical-align: bottom;
}
embed,
object,
iframe {
  border: 0;
  vertical-align: bottom;
}
summary {
  display: list-item;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  text-align: left;
}
td,
th {
  vertical-align: top;
}
th {
  text-align: left;
  font-weight: bold;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
.pc-br {
  display: none;
}
@media only screen and (max-width: 750px) {
  .pc-br {
    display: inline;
  }
}
button,
input,
optgroup,
select,
textarea {
  vertical-align: middle;
  color: inherit;
  font: inherit;
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  outline: 0;
}
select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select::-ms-expand {
  display: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}
button[disabled],
[type='button'][disabled],
[type='reset'][disabled],
[type='submit'][disabled] {
  cursor: default;
}
button-moz-focus-inner,
[type='button']-moz-focus-inner,
[type='reset']-moz-focus-inner,
[type='submit']-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button-moz-focusring,
[type='button']-moz-focusring,
[type='reset']-moz-focusring,
[type='submit']-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}
legend {
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0 /* 3 */;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']-webkit-inner-spin-button,
[type='number']-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']-webkit-search-decoration {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
-webkit-input-placeholder {
  font: inherit;
}
-ms-input-placeholder {
  font: inherit;
}
-ms-input-placeholder {
  font: inherit;
}
placeholder {
  font: inherit;
}
label {
  box-sizing: border-box;
}
label[for] {
  cursor: pointer;
}
.dev-space {
  height: 100px;
}
.top-banner {
  position: relative;
  margin: 0 auto;
  padding: 52px 10px 38px;
  width: 960px;
  min-height: 100px;
  background-image: url("assets/images/top-banner_bg.svg");
  border-radius: 10px;
}
@media only screen and (min-width: 751px) and (max-width: 768px) {
  .top-banner {
    width: calc(100vw - 20px);
  }
}
@media only screen and (max-width: 750px) {
  .top-banner {
    padding: 30px 10px 16px;
    width: calc(100vw - 40px);
  }
}
.top-banner-headding {
  position: absolute;
  padding: 5px 12px 5px 0;
  min-width: 110px;
  text-align: right;
  left: 0;
  top: 15px;
  color: #fff;
  font-size: 17px;
  background-color: rgba(233,84,107,0.9);
}
.top-banner-inner {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  width: 940px;
}
@media only screen and (min-width: 751px) and (max-width: 768px) {
  .top-banner-inner {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .top-banner-inner {
    display: block;
    width: 100%;
  }
}
.top-banner-inner2 {
  display: flex;
  justify-content: space-around;
  margin: 32px auto 0;
  width: 940px;
}
@media only screen and (min-width: 751px) and (max-width: 768px) {
  .top-banner-inner2 {
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .top-banner-inner2 {
    display: block;
    width: 100%;
  }
}
.top-banner-left,
.top-banner-center,
.top-banner-right {
  width: 280px;
  text-align: center;
}
@media only screen and (min-width: 751px) and (max-width: 768px) {
  .top-banner-left,
  .top-banner-center,
  .top-banner-right {
    width: 31%;
  }
}
@media only screen and (max-width: 750px) {
  .top-banner-left,
  .top-banner-center,
  .top-banner-right {
    margin-top: 30px;
    width: 100%;
  }
}
.top-banner-image {
  width: 100%;
}
.top-banner-title {
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (min-width: 751px) and (max-width: 768px) {
  .top-banner-title {
    font-size: 13px;
  }
}
.top-banner-photo {
  display: block;
  position: relative;
  margin-top: 12px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 3px 3px rgba(0,0,0,0.25);
  transition: 0.3s ease;
}
.top-banner-photo:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 750px) {
  .top-banner-photo:hover {
    opacity: 1;
  }
}
.top-banner-tag1,
.top-banner-tag2,
.top-banner-tag3,
.top-banner-tag4,
.top-banner-tag5 {
  position: absolute;
  padding: 7px 16px;
  min-width: 110px;
  right: 0;
  bottom: 9px;
  color: #fff;
  font-size: 15px;
  border-radius: 15px 0 0 15px;
}
.top-banner-tag1 {
  background-color: rgba(251,176,59,0.85);
}
.top-banner-tag2 {
  background-color: rgba(233,84,107,0.85);
}
.top-banner-tag3 {
  background-color: rgba(140,198,63,0.85);
}
.top-banner-tag4 {
  background-color: rgba(216,12,24,0.85);
}
.top-banner-tag5 {
  background-color: rgba(251,176,59,0.85);
}
.pages {
  margin-top: 100px !important;
}
@media only screen and (max-width: 750px) {
  .pages {
    margin-top: 80px !important;
  }
}
.no-touch .pages {
  margin-top: 68px !important;
}
.about-title {
  padding: 60px 0 24px;
  background: url("assets/images/about/about-back.png");
}
.about-title >img {
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .about-title {
    padding: 40px 20px 24px;
  }
  .about-title >img {
    width: 100%;
  }
}
.about-inner {
  margin: 0 auto;
  width: 980px;
  background: url("assets/images/about/about-inner.png") center top no-repeat;
}
@media only screen and (max-width: 750px) {
  .about-inner {
    width: 100%;
    padding: 0 20px;
    background: url("assets/images/about/about-inner-sp.png") left 20px no-repeat;
    background-size: 100% auto;
  }
}
.about-subtitle {
  padding: 90px 0 80px;
  text-align: center;
}
.about-subtitle >img {
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .about-subtitle {
    padding: 40px 0 50px;
  }
  .about-subtitle >img {
    width: 100%;
  }
}
.about-explanation {
  padding: 28px 0;
  width: 100%;
  background-color: rgba(193,239,250,0.36);
}
.about-explanation >p {
  display: table;
  margin: 0 auto;
  line-height: 1.9;
  font-size: 22px;
}
@media only screen and (max-width: 750px) {
  .about-explanation {
    padding: 30px 20px;
  }
  .about-explanation >p {
    display: block;
    margin: 0;
  }
}
.about-link {
  padding: 90px 0 120px;
}
@media only screen and (max-width: 750px) {
  .about-link {
    padding: 42px 0;
  }
}
.about-button {
  display: block;
  margin: 0 auto;
  padding: 22px 0;
  width: 820px;
  text-align: center;
  background-color: #f0df26;
  border-radius: 18px;
  box-shadow: 3px 3px rgba(204,204,204,0.5);
  transition: 0.4s ease;
}
.about-button >img {
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .about-button {
    padding: 20px 10px;
    width: 100%;
  }
  .about-button >img {
    width: 100%;
  }
}
.about-button:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 750px) {
  .about-button:hover {
    opacity: 1;
  }
}
.contact {
  margin-top: 100px !important;
}
@media only screen and (max-width: 750px) {
  .contact {
    margin-top: 80px !important;
  }
}
.no-touch .contact {
  margin-top: 68px !important;
}
@media only screen and (max-width: 750px) {
  .charm {
    width: 100%;
    padding: 0 20px;
  }
}
.charm-title {
  padding: 60px 0 0;
}
.charm-title >img {
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .charm-title {
    margin: 0 -10px;
    padding: 40px 0 20px;
  }
  .charm-title >img {
    width: 100%;
  }
}
.charm-arrow {
  margin-top: 40px;
}
.charm-arrow >img {
  margin: 0 auto;
  display: block;
}
@media only screen and (max-width: 750px) {
  .charm-arrow {
    margin-top: 20px;
  }
}
.wpcf7 {
  margin: 40px auto 0;
  width: 820px;
}
@media only screen and (max-width: 750px) {
  .wpcf7 {
    margin: 20px auto 0;
    width: 100%;
  }
}
.charm-item {
  display: table;
  margin-bottom: 30px;
  table-layout: fixed;
  width: 100%;
  font-size: 22px;
}
.charm-item >span {
  display: table-cell;
}
.charm-item +br {
  display: none;
}
@media only screen and (max-width: 750px) {
  .charm-item {
    display: block;
  }
  .charm-item >span {
    display: block;
  }
}
.charm-item2 {
  display: block;
  margin: 20px 0 60px;
  width: 100%;
  font-size: 22px;
}
.charm-item2 >span {
  display: block;
  width: 100%;
}
.charm-item2 +br {
  display: none;
}
@media only screen and (max-width: 750px) {
  .charm-item2 {
    margin: 20px 0 80px;
  }
}
.charm-headding {
  width: 270px;
}
@media only screen and (max-width: 750px) {
  .charm-headding {
    padding: 0 0 12px;
  }
}
.charm-headding2 {
  position: relative;
  padding: 12px 0;
}
@media only screen and (max-width: 750px) {
  .charm-headding2 {
    padding: 0 0 12px;
  }
}
.charm-note {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
}
@media only screen and (max-width: 750px) {
  .charm-note {
    top: inherit;
    bottom: -270px;
    font-size: 16px;
  }
}
.charm-required {
  color: #f06836;
}
.wpcf7-text {
  padding: 12px 10px;
  width: 100%;
  border: solid 1px #ccc;
}
.wpcf7-select {
  padding: 12px 10px;
  width: 100%;
  border: solid 1px #ccc;
  background: url("assets/images/provide-charm/charm-down.png") right 30px center no-repeat;
}
.wpcf7-textarea {
  padding: 12px 10px;
  width: 100%;
  height: 340px;
  line-height: 1.5;
  font-size: 18px;
  border: solid 1px #ccc;
}
@media only screen and (max-width: 750px) {
  .wpcf7-textarea {
    height: 240px;
  }
}
.charm-button {
  margin: 0 auto;
  width: 820px;
}
@media only screen and (max-width: 750px) {
  .charm-button {
    width: 100%;
  }
}
.wpcf7-confirm,
.wpcf7-submit {
  display: block;
  width: 820px;
  height: 100px;
  text-indent: -9999px;
  background: #f0df26 url("assets/images/provide-charm/confirm-button.png") center no-repeat;
  border-radius: 18px;
  box-shadow: 3px 3px rgba(204,204,204,0.5);
  transition: 0.4s ease;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .wpcf7-confirm,
  .wpcf7-submit {
    width: 100%;
    height: 70px;
    background: #f0df26 url("assets/images/provide-charm/confirm-button-sp.png") center no-repeat;
  }
}
.wpcf7-back {
  display: inline-block;
  width: 300px;
  height: 80px;
  text-indent: -9999px;
  background: #9f9f9f url("assets/images/provide-charm/back-button.png") center no-repeat;
  border-radius: 18px;
  box-shadow: 3px 3px rgba(204,204,204,0.5);
  transition: 0.4s ease;
  cursor: pointer;
}
@media only screen and (max-width: 750px) {
  .wpcf7-back {
    display: block;
    margin-bottom: 20px;
    width: 100%;
    height: 70px;
    background: #9f9f9f url("assets/images/provide-charm/back-button-sp.png") center no-repeat;
  }
}
.wpcf7-confirm:hover,
.wpcf7-back:hover,
.wpcf7-submit:hover {
  opacity: 0.8;
}
.charm-aside {
  margin: 80px auto 0;
  width: 780px;
  line-height: 1.8;
  font-size: 14px;
}
@media only screen and (max-width: 750px) {
  .charm-aside {
    margin: 40px auto 0;
    width: 100%;
    font-size: 18px;
  }
}
.charm-subheadding {
  margin: 40px auto 0;
  width: 780px;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .charm-subheadding {
    width: 100%;
    font-size: 20px;
  }
}
.charm-privacypolicy {
  margin: 16px auto 60px;
  width: 780px;
  line-height: 1.8;
  font-size: 14px;
}
.charm-privacypolicy >a {
  color: #00a4cc;
  font-weight: bold;
}
.charm-privacypolicy >a:hover,
.charm-privacypolicy >span {
  text-decoration: underline;
}
@media only screen and (max-width: 750px) {
  .charm-privacypolicy {
    width: 100%;
    font-size: 18px;
  }
  .charm-privacypolicy >a:hover,
  .charm-privacypolicy >span {
    text-decoration: none;
  }
}
.charm-provided {
  margin: 100px auto 0;
  width: 960px;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
@media only screen and (max-width: 750px) {
  .charm-provided {
    margin: 50px auto 0;
    width: 100%;
    line-height: 1.3;
    font-size: 30px;
  }
}
.charm-thanks {
  margin: 30px auto 0;
  padding-bottom: 80px;
  width: 960px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  border-bottom: dotted 1px #00a0d2;
}
@media only screen and (max-width: 750px) {
  .charm-thanks {
    margin: 24px auto 0;
    width: 100%;
    font-size: 19px;
  }
}
.wpcf7-not-valid-tip {
  margin-top: 10px;
  font-size: 18px;
  direction: ltr !important;
}
.wpcf7c-conf {
  background-color: none !important;
}
.wpcf7-response-output {
  margin: 80px 0 0;
  padding: 40px !important;
  font-size: 18px !important;
  background-color: rgba(193,239,250,0.36);
}
@media only screen and (max-width: 750px) {
  .wpcf7-response-output {
    margin: 50px 0 0 !important;
    padding: 20px !important;
    line-height: 1.3;
    font-size: 20px !important;
  }
}
.wpcf7 form .wpcf7-response-output {
  margin: 80px 0 0;
  border: solid 1px #00a0d2;
}
.wpcf7 form .wpcf7-response-output padding 30px {
  font-size: 16px !important;
}
.wpcf7 form.sent .wpcf7-response-output {
  border: solid 1px #00a0d2;
}
.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border: solid 1px #00a0d2;
}
.wpcf7 form.spam .wpcf7-response-output {
  border: solid 1px #00a0d2;
}
.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output {
  border: solid 1px #00a0d2;
}
.stamp {
  margin-top: 102px !important;
}
.stamp .post-password-form {
  display: block;
  margin: 80px auto;
  width: 960px;
}
.stamp .post-password-form >p {
  font-size: 16px;
  margin-bottom: 40px;
}
.stamp .post-password-form input[type="password"] {
  padding: 5px;
  border: solid 1px #ccc;
}
.stamp .post-password-form input[type="submit"] {
  margin-left: 20px;
  padding: 10px 30px;
  color: #fff;
  background-color: #999;
}
@media only screen and (max-width: 750px) {
  .stamp {
    margin-top: 84px !important;
  }
  .stamp .post-password-form {
    width: 100%;
  }
  .stamp .post-password-form input[type="password"] {
    width: 150px;
  }
  .stamp .post-password-form input[type="submit"] {
    padding: 10px 20px;
  }
}
.stamp-hero {
  background: #ffe33f url("assets/images/stamp-rally/stamp-hero-back.png") center top;
  border-top: solid 6px #ffc617;
}
.stamp-main {
  margin: 20px auto 0;
  padding-bottom: 108px;
  width: 960px;
}
@media only screen and (max-width: 750px) {
  .stamp-main {
    padding-bottom: 50px;
    width: 100%;
  }
}
.hero-title-sp {
  margin: 0 auto;
  width: calc(100% - 10px);
  height: auto;
}
.stamp-text {
  margin: -56px auto 0;
}
.stamp-text >img {
  margin: 0 auto;
}
@media only screen and (max-width: 750px) {
  .stamp-text {
    margin: -42px auto 0;
  }
}
.hero-info-sp {
  width: calc(100% - 40px);
  height: auto;
}
.stamp-hit {
  position: relative;
  padding: 28px 0 40px;
  background: #ffe33f url("assets/images/stamp-rally/stamp-hit-back.png") center top;
}
.stamp-hit >h3 {
  position: absolute;
  z-index: 1;
  left: 50%;
  top: -54px;
  transform: translate(-50%, 0);
}
@media only screen and (max-width: 750px) {
  .stamp-hit {
    padding: 38px 0 20px;
    width: 100%;
  }
  .stamp-hit >h3 {
    top: -26px;
  }
}
@media only screen and (max-width: 750px) {
  .hit-title-sp {
    width: 319px;
    height: auto;
  }
}
.stamp-three {
  position: relative;
  margin: 0 auto;
  width: 960px;
  height: 365px;
  background-color: #fff;
  border-radius: 24px;
}
.stamp-three >img {
  position: absolute;
  left: 40px;
  top: 28px;
}
@media only screen and (max-width: 750px) {
  .stamp-three {
    width: calc(100% - 20px);
    height: 155px;
    border-radius: 12px;
  }
  .stamp-three >img {
    left: 10px;
    top: 14px;
  }
}
.stamp-gift {
  position: absolute;
  left: 40px;
  top: 28px;
}
@media only screen and (max-width: 750px) {
  .stamp-gift {
    left: 10px;
    top: 14px;
  }
}
.stamp-note {
  position: absolute;
  width: auto;
  right: 35px;
  bottom: 14px;
}
@media only screen and (max-width: 750px) {
  .stamp-note {
    right: 15px;
    bottom: 10px;
    text-align: right;
  }
}
@media only screen and (max-width: 750px) {
  .hit-item-sp {
    width: calc(100% - 10px);
    height: auto;
  }
}
@media only screen and (max-width: 750px) {
  .hit-note-sp {
    width: 50%;
    height: auto;
  }
}
.stamp-joining {
  position: relative;
  margin: 60px auto 0;
  width: 960px;
  height: 238px;
  background-color: #fffde3;
  border: solid 2px #ea5514;
  border-radius: 24px;
}
.stamp-joining >h4 {
  position: absolute;
  left: 264px;
  top: -20px;
}
@media only screen and (max-width: 750px) {
  .stamp-joining {
    margin: 22px auto 0;
    width: calc(100% - 20px);
    height: 124px;
    border-radius: 12px;
  }
  .stamp-joining >h4 {
    position: absolute;
    left: 50%;
    top: -12px;
    transform: translate(-50%, 0);
  }
}
@media only screen and (max-width: 750px) {
  .joining-title-sp {
    width: 320px;
    height: auto;
  }
}
.stamp-qr {
  position: absolute;
  left: 25px;
  top: -43px;
}
.stamp-step {
  position: absolute;
  left: 238px;
  top: 32px;
}
@media only screen and (max-width: 750px) {
  .stamp-step {
    left: 15px;
    top: 22px;
  }
}
@media only screen and (max-width: 750px) {
  .joining-way-sp {
    width: calc(100% - 20px);
    height: auto;
  }
}
.stamp-link {
  margin: 60px auto 0;
  width: 960px;
}
@media only screen and (max-width: 750px) {
  .stamp-link {
    margin: 30px auto 0;
    width: 100%;
  }
}
.stamp-walking {
  margin: 80px auto 0;
  width: 960px;
}
@media only screen and (max-width: 750px) {
  .stamp-walking {
    margin: 40px auto 0;
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .walking-title-sp {
    margin: 0 auto;
    width: calc(100% - 20px);
  }
}
.walking-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .walking-list {
    display: block;
    margin: 20px auto 0;
    width: calc(100% - 20px);
  }
}
.walking-point {
  overflow: hidden;
  margin-bottom: 30px;
  border: solid 1px #ccc;
  border-radius: 12px;
  box-shadow: 3px 3px rgba(204,204,204,0.8);
}
@media only screen and (max-width: 750px) {
  .walking-point {
    margin-bottom: 20px;
  }
}
.walking-link {
  display: block;
}
.walking-link:hover >.walking-photo >img {
  opacity: 0.6;
}
@media only screen and (max-width: 750px) {
  .walking-link:hover >.walking-photo >img {
    opacity: 1;
  }
}
.walking-photo {
  position: relative;
  width: 465px;
  height: 250px;
}
.walking-photo >img {
  transition: 0.35s ease;
}
.walking-photo:after {
  display: block;
  position: absolute;
  content: url("assets/images/stamp-rally/walking-stamp.png");
  right: 10px;
  top: 6px;
}
@media only screen and (max-width: 750px) {
  .walking-photo {
    width: 100%;
    height: inherit;
  }
  .walking-photo >img {
    width: 100%;
    transition: none;
  }
  .walking-photo:after {
    content: url("assets/images/stamp-rally/walking-stamp-sp.png");
  }
}
.walking-text {
  position: relative;
  width: 465px;
  height: 70px;
}
.walking-text >img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 750px) {
  .walking-text {
    width: 100%;
    height: 60px;
  }
}
.stamp-enjoy {
  margin: 30px auto 0;
  width: 960px;
}
@media only screen and (max-width: 750px) {
  .stamp-enjoy {
    margin: 40px auto 0;
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .enjoy-title-sp {
    margin: 0 auto;
    width: calc(100% - 20px);
  }
}
.enjoy-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}
@media only screen and (max-width: 750px) {
  .enjoy-list {
    margin: 20px auto 0;
    width: calc(100% - 20px);
  }
}
.enjoy-point {
  overflow: hidden;
  margin-bottom: 30px;
  border: solid 1px #ccc;
  border-radius: 12px;
  box-shadow: 3px 3px rgba(204,204,204,0.8);
}
@media only screen and (max-width: 750px) {
  .enjoy-point {
    overflow: hidden;
    margin-bottom: 20px;
    width: calc(50% - 5px);
  }
}
.enjoy-link {
  display: block;
}
.enjoy-link:hover >.enjoy-photo >img {
  opacity: 0.6;
}
@media only screen and (max-width: 750px) {
  .enjoy-link:hover >.enjoy-photo >img {
    opacity: 1;
  }
}
.enjoy-photo {
  position: relative;
  width: 300px;
  height: 180px;
}
.enjoy-photo >img {
  transition: 0.35s ease;
}
.enjoy-photo:after {
  display: block;
  position: absolute;
  content: url("assets/images/stamp-rally/enjoy-stamp.png");
  right: 10px;
  top: 6px;
}
@media only screen and (max-width: 750px) {
  .enjoy-photo {
    width: 100%;
    height: auto;
  }
  .enjoy-photo >img {
    width: 100%;
    transition: none;
  }
  .enjoy-photo:after {
    content: url("assets/images/stamp-rally/enjoy-stamp-sp.png");
  }
}
.enjoy-text {
  position: relative;
  width: 300px;
  height: 70px;
}
.enjoy-text >img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 750px) {
  .enjoy-text {
    width: 100%;
    height: 38px;
  }
  .enjoy-text >img {
    width: auto;
    height: 10px;
    max-width: 90%;
    max-height: 10px;
  }
}
.stamp-map {
  margin: 75px auto 0;
  padding-bottom: 45px;
  width: 960px;
}
@media only screen and (max-width: 750px) {
  .stamp-map {
    margin: 20px auto 0;
    padding-bottom: 10px;
    width: 100%;
  }
}
@media only screen and (max-width: 750px) {
  .spot-title-sp {
    margin: 0 auto;
    width: calc(100% - 20px);
  }
}
.map-frame {
  margin: 40px 0 60px;
}
.map-frame >iframe {
  width: 100%;
  height: 480px;
}
@media only screen and (max-width: 750px) {
  .map-frame {
    margin: 20px 0 40px;
    width: 100%;
  }
  .map-frame >iframe {
    display: block;
    margin: 0 auto;
    width: calc(100% - 20px);
    height: 300px;
  }
}
.stamp-rally-btn {
  display: block;
  margin: 0 auto;
  width: 820px;
  height: 100px;
  background: #fddb3c url("assets/images/stamp-rally/stamp-rally-btn.png") 30px center no-repeat;
  border-radius: 24px;
  box-shadow: 3px 3px rgba(204,204,204,0.8);
  transition: 0.35s ease;
}
.stamp-rally-btn:hover {
  background-color: #fc0;
}
@media only screen and (max-width: 750px) {
  .stamp-rally-btn {
    width: 333px;
    height: 50px;
    background: #fddb3c url("assets/images/stamp-rally/stamp-rally-btn.png") center no-repeat;
    background-size: 292px auto;
    border-radius: 12px;
    box-shadow: 4px 4px rgba(204,204,204,0.8);
    transition: none;
  }
  .stamp-rally-btn:hover {
    background-color: #fddb3c;
  }
}
