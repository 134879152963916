.stamp
  margin-top 102px !important
  .post-password-form
    display block
    margin 80px auto
    width 960px
    >p
      font-size 16px
      margin-bottom 40px
    input[type="password"]
      padding 5px
      border solid 1px #ccc
    input[type="submit"]
      margin-left 20px
      padding 10px 30px
      color #fff
      background-color #999

  +media_sp()
    margin-top 84px !important
    .post-password-form
      width 100%
      input[type="password"]
        width 150px
      input[type="submit"]
        padding 10px 20px

.stamp-hero
  background #ffe33f url(assets/images/stamp-rally/stamp-hero-back.png) center top
  border-top solid 6px #ffc617

.stamp-main
  margin 20px auto 0
  padding-bottom 108px
  width 960px

  +media_sp()
    padding-bottom 50px
    width 100%

.hero-title-sp
  margin 0 auto
  width calc(100% - 10px)
  height auto

.stamp-text
  margin -56px auto 0
  >img
    margin 0 auto

  +media_sp()
    margin -42px auto 0

.hero-info-sp
  width calc(100% - 40px)
  height auto

.stamp-hit
  position relative
  padding 28px 0 40px
  background #ffe33f url(assets/images/stamp-rally/stamp-hit-back.png) center top
  >h3
    position absolute
    z-index 1
    left 50%
    top -54px
    transform translate(-50%, 0)

  +media_sp()
    padding 38px 0 20px
    width 100%
    >h3
      top -26px

.hit-title-sp
  +media_sp()
    width 319px
    height auto

.stamp-three
  position relative
  margin 0 auto
  width 960px
  height 365px
  background-color #fff
  border-radius 24px
  >img
    position absolute
    left 40px
    top 28px

  +media_sp()
    width calc(100% - 20px)
    height 155px
    border-radius 12px
    >img
      left 10px
      top 14px

.stamp-gift
  position absolute
  left 40px
  top 28px

  +media_sp()
    left 10px
    top 14px

.stamp-note
  position absolute
  width auto
  right 35px
  bottom 14px

  +media_sp()
    right 15px
    bottom 10px
    text-align right

.hit-item-sp
  +media_sp()
    width calc(100% - 10px)
    height auto

.hit-note-sp
  +media_sp()
    width 50%
    height auto

.stamp-joining
  position relative
  margin 60px auto 0
  width 960px
  height 238px
  background-color #fffde3
  border solid 2px #ea5514
  border-radius 24px
  >h4
    position absolute
    left 264px
    top -20px

  +media_sp()
    margin 22px auto 0
    width calc(100% - 20px)
    height 124px
    border-radius 12px
    >h4
      position absolute
      left 50%
      top -12px
      transform translate(-50%, 0)

.joining-title-sp
  +media_sp()
    width 320px
    height auto

.stamp-qr
  position absolute
  left 25px
  top -43px

.stamp-step
  position absolute
  left 238px
  top 32px

  +media_sp()
    left 15px
    top 22px

.joining-way-sp
  +media_sp()
    width calc(100% - 20px)
    height auto

.stamp-link
  margin 60px auto 0
  width 960px

  +media_sp()
    margin 30px auto 0
    width 100%

.stamp-walking
  margin 80px auto 0
  width 960px

  +media_sp()
    margin 40px auto 0
    width 100%

.walking-title-sp
  +media_sp()
    margin 0 auto
    width calc(100% - 20px)

.walking-list
  display flex
  flex-wrap wrap
  justify-content space-between
  margin-top 30px

  +media_sp()
    display block
    margin 20px auto 0
    width calc(100% - 20px)

.walking-point
  overflow hidden
  margin-bottom 30px
  border solid 1px #ccc
  border-radius 12px
  box-shadow 3px 3px rgba(#ccc, .8)

  +media_sp()
    margin-bottom 20px

.walking-link
  display block
  &:hover
    >.walking-photo
      >img
        opacity .6

  +media_sp()
    &:hover
      >.walking-photo
        >img
          opacity 1

.walking-photo
  position relative
  width 465px
  height 250px
  >img
    transition .35s ease
  &:after
    display block
    position absolute
    content url(assets/images/stamp-rally/walking-stamp.png)
    right 10px
    top 6px

  +media_sp()
    width 100%
    height inherit
    >img
      width 100%
      transition none
    &:after
      content url(assets/images/stamp-rally/walking-stamp-sp.png)
.walking-text
  position relative
  width 465px
  height 70px
  >img
    display block
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

  +media_sp()
    width 100%
    height 60px

.stamp-enjoy
  margin 30px auto 0
  width 960px

  +media_sp()
    margin 40px auto 0
    width 100%

.enjoy-title-sp
  +media_sp()
    margin 0 auto
    width calc(100% - 20px)

.enjoy-list
  display flex
  flex-wrap wrap
  justify-content space-between
  margin-top 30px

  +media_sp()
    margin 20px auto 0
    width calc(100% - 20px)

.enjoy-point
  overflow hidden
  margin-bottom 30px
  border solid 1px #ccc
  border-radius 12px
  box-shadow 3px 3px rgba(#ccc, .8)

  +media_sp()
    overflow hidden
    margin-bottom 20px
    width calc(50% - 5px)

.enjoy-link
  display block
  &:hover
    >.enjoy-photo
      >img
        opacity .6

  +media_sp()
    &:hover
      >.enjoy-photo
        >img
          opacity 1

.enjoy-photo
  position relative
  width 300px
  height 180px
  >img
    transition .35s ease
  &:after
    display block
    position absolute
    content url(assets/images/stamp-rally/enjoy-stamp.png)
    right 10px
    top 6px

  +media_sp()
    width 100%
    height auto
    >img
      width 100%
      transition none
    &:after
      content url(assets/images/stamp-rally/enjoy-stamp-sp.png)

.enjoy-text
  position relative
  width 300px
  height 70px
  >img
    display block
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

  +media_sp()
    width 100%
    height 38px
    >img
      width auto
      height 10px
      max-width 90%
      max-height 10px

.stamp-map
  margin 75px auto 0
  padding-bottom 45px
  width 960px

  +media_sp()
    margin 20px auto 0
    padding-bottom 10px
    width 100%

.spot-title-sp
  +media_sp()
    margin 0 auto
    width calc(100% - 20px)

.map-frame
  margin 40px 0 60px
  >iframe
    width 100%
    height 480px

  +media_sp()
    margin 20px 0 40px
    width 100%
    >iframe
      display block
      margin 0 auto
      width calc(100% - 20px)
      height 300px

// div[data-tooltip="この地図にスターを付けて Google マップで表示する"]
//   background #fff
//   +div
//     display none !important

.stamp-rally-btn
  display block
  margin 0 auto
  width 820px
  height 100px
  background #fddb3c url(assets/images/stamp-rally/stamp-rally-btn.png) 30px center no-repeat
  border-radius 24px
  box-shadow 3px 3px rgba(#ccc, .8)
  transition .35s ease
  &:hover
    background-color #ffcc00

  +media_sp()
    width 333px
    height 50px
    background #fddb3c url(assets/images/stamp-rally/stamp-rally-btn.png) center no-repeat
    background-size 292px auto
    border-radius 12px
    box-shadow 4px 4px rgba(#ccc, .8)
    transition none
    &:hover
      background-color #fddb3c
