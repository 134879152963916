$media_max = {
  lp: 1024px,
  tb: 834px,
  pb: 768px,
  sp: 750px
}

$media_min = {
  mx: $media_max.lp+1,
  lp: $media_max.tb+1,
  tb: $media_max.pb+1,
  pb: $media_max.sp+1,
}

// ▽ 大画面
media_mx()
  @media only screen and (min-width: $media_min.mx)
    {block}

// ▽ デフォルト
media_lp()
  @media only screen and (min-width: $media_min.lp) and (max-width: $media_max.lp)
    {block}

// ▽ タッチデバイス
media_td()
  @media only screen and (max-width: $media_max.tb)
    {block}

media_touch()
  @media (hover: none)
    {block}

// ▽ iPad PRO - iPad mini まで
media_ip()
  @media only screen and (min-width: $media_min.tb) and (max-width: $media_max.lp)
    {block}

// ▽ タブレット
media_tb()
  @media only screen and (min-width: $media_min.tb) and (max-width: $media_max.tb)
    {block}


// ▽ モバイルデバイス
media_md()
  @media only screen and (max-width: $media_max.pb)
    {block}

// ▽ ファブレット
media_pb()
  @media only screen and (min-width: $media_min.pb) and (max-width: $media_max.pb)
    {block}

// ▽ iPad mini 以下
media_ap()
  @media only screen and (max-width: $media_max.pb)
    {block}

// ▽ スマートフォン
media_sp()
  @media only screen and (max-width: $media_max.sp)
    {block}

// ▽ IE
media_ie()
  @media all and (-ms-high-contrast:none)
    {block}

// ▽ プリンター
media_pr()
  @media print
    {block}
